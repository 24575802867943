import { Badge, Button, Card, InlineStack, Link, Page, Text, Thumbnail } from '@shopify/polaris';
import { DeleteIcon } from '@shopify/polaris-icons';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import helpers from 'helpers';
import dateandtime from 'date-and-time';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Pagination from 'components/pagination';
// import SkeletonLoading from 'components/skeletonPageLoading';
// import SimpleFilter from 'components/simpleFilter';
import { Helmet } from 'react-helmet-async';
import ProductCategoryModalAddAndEdit from './product_category.edit.modal';
import { TypedProductCategory } from 'interface/product.model';
import { useBatchReorder, useDeleteProductCategory, useGetProductCategories } from 'queries/product_category.query';
import { useDeleteConfirm } from 'context/DeleteConfirmContext';
export default function ProductCollectionList() {
  const [entities, setEntities] = useState<TypedProductCategory[] | null>(null);
  const [totalItems, setTotalItems] = useState(0);
  const {
    mutateAsync: deleteProductCategoryQuery
  } = useDeleteProductCategory();
  const {
    mutate: batchReorder
  } = useBatchReorder();
  // const dispatch = useAppDispatch();
  // const history = useNavigate();

  const rootSearchPath = window.location.search;
  /**
   * If user apply filter, it will add to URL, then parse URL back to initial state
   */
  let StringQuery: any = helpers.ExtractUrl(rootSearchPath) || false;
  const initialQuery = {
    query: '',
    page: 1,
    limit: 20,
    sort: 'category_order:asc'
  };
  const [mainQuery, setMainQuery] = useState<any>({
    ...initialQuery,
    ...StringQuery
  });
  const {
    refetch: getEntities,
    data: productCategoryData
  } = useGetProductCategories(mainQuery);
  useEffect(() => {
    if (productCategoryData) {
      let {
        body,
        totalItems
      } = productCategoryData;
      setEntities(body);
      setTotalItems(totalItems);
    }
  }, [productCategoryData]);

  /**
   * Change page number
   * Must be mainQuery or it will reset mainQuery. BUG!
   */
  const onChangePagination = useCallback((numPage: number, limit: number) => {
    setMainQuery(prevMainQuery => ({
      ...prevMainQuery,
      page: numPage,
      limit: limit
    }));
  }, []);
  const reduceRequest = useCallback(mainQuery => {
    getEntities();
    setMainQuery(mainQuery);
  }, []);
  const reduceRequestMemo = useMemo(() => {
    return helpers.debounce(_value => {
      reduceRequest?.call(this, _value);
    }, 500);
  }, []);
  useEffect(() => {
    let buildURLSearch = helpers.buildEndUrl(mainQuery);
    if (window.location.search !== buildURLSearch) {
      window.history.replaceState(null, 'Product Category', '/product_category' + buildURLSearch);
    }
    reduceRequestMemo(mainQuery);
  }, [mainQuery]);
  const [showModal, setShowModal] = useState(false);
  const modalEntity = useRef(null);
  const clickToShowEditModal = useCallback((categoryData: TypedProductCategory) => {
    modalEntity.current = categoryData;
    setShowModal(true);
  }, []);

  // delete modal
  const {
    showDeleteConfirm
  } = useDeleteConfirm();
  const handleDeleteProductCategory = useCallback(async product_category_id => {
    const confirmed = await showDeleteConfirm('Xóa chuyên mục sản phẩm?', 'Chỉ các chuyên mục không có sản phẩm nào mới có thể xóa bỏ. Nếu chuyên mục còn sản phẩm, vui lòng di chuyển sang chuyên mục khác trước.');
    if (confirmed) {
      // Xử lý hành động xóa
      try {
        await deleteProductCategoryQuery(product_category_id);
      } catch (e) {}
    }
  }, []);
  const handleOnDragEnd = useCallback(result => {
    if (!result.destination) return;
    const items = [...entities];
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setEntities(items);

    // Gửi danh sách sắp xếp mới lên API (nếu có)
    const reorderedData = items.reduce((acc, item, index) => {
      acc[item.category_id] = index + 1;
      return acc;
    }, {});

    // Thực hiện gửi API cập nhật thứ tự (nếu có API)
    batchReorder(reorderedData);
  }, [entities]);
  return <>
      <Helmet>
        <title>Quản lý danh mục sản phẩm</title>
      </Helmet>

      <ProductCategoryModalAddAndEdit show={showModal} onClose={() => {
      modalEntity.current = null;
      setShowModal(false);
    }} entity={modalEntity.current} />

      <Page title="Danh mục sản phẩm" primaryAction={{
      content: 'Tạo mới danh mục',
      onAction: () => setShowModal(true)
    }}>
        <Card padding={'0'}>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <div className="Polaris-DataTable__ScrollContainer">
              <Droppable droppableId="table">
                {provided => <table {...provided.droppableProps} ref={provided.innerRef} className="Polaris-DataTable__Table">
                    <thead>
                    <tr>
                      <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--header">STT</th>
                      <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--header">Tên danh mục</th>
                      <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--header">Mô tả</th>
                      <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--header">Hiển thị</th>
                      <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--header">Sản phẩm</th>
                      <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--header">Ngày tạo</th>
                      <th className="Polaris-DataTable__Cell Polaris-DataTable__Cell--header">-</th>
                    </tr>
                    </thead>
                    <tbody>
                    {entities?.map((categoryData, index) => <Draggable key={categoryData.category_id} draggableId={categoryData.category_id.toString()} index={index}>
                        {provided => <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps} className="Polaris-DataTable__TableRow Polaris-DataTable--hoverable">
                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">{index + 1}</td>
                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                              <Link removeUnderline url={`/product_category/view/${categoryData.category_id}`} key={index}>
                                {categoryData.category_thumbnail ? <InlineStack blockAlign="center" gap={'100'}>
                                    <Thumbnail size="small" source={helpers.getMediaLink(categoryData.category_thumbnail_to_media?.media_thumbnail['128x128'])} alt={''} />
                                    <Text as="span">{categoryData.category_name}</Text>
                                  </InlineStack> : <Text as="span">{categoryData.category_name}</Text>}
                              </Link>
                            </td>
                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                              <Text as="span">{helpers.trimMiddleString(categoryData.category_excerpt, 30, 10)}</Text>
                            </td>
                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                              {categoryData.category_status === 1 ? <Badge tone="success">Có</Badge> : <Badge tone="warning">Không</Badge>}
                            </td>
                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                              {categoryData._count?.product_to_category || 0}
                            </td>
                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                              {dateandtime.format(new Date(Number(categoryData.createdAt)), 'DD-MM-YYYY')}
                            </td>
                            <td className="Polaris-DataTable__Cell Polaris-DataTable__Cell--verticalAlignMiddle">
                              <Button variant="plain" onClick={() => handleDeleteProductCategory(categoryData.category_id)} icon={DeleteIcon}></Button>
                            </td>
                          </tr>}
                      </Draggable>)}
                    {provided.placeholder}
                    </tbody>
                  </table>}
              </Droppable>
            </div>
          </DragDropContext>
        </Card>
        <br />
        {totalItems > 0 ? <Pagination TotalRecord={totalItems} onChange={onChangePagination} pageSize={Number(mainQuery?.limit)} activeCurrentPage={Number(mainQuery?.page)} /> : null}
      </Page>
    </>;
}